<template>
  <div v-if="isComponentVisible">
    <h3 class="line-header">
      <img
        src="../assets/quality_icon_blue.svg"
        alt="Icon quality"
      > {{ $t('words.quality') }}
    </h3>
    <div class="dataset-quality">
      <img
        src="../assets/quality_icon_green.svg"
        alt="Quality label"
        class="quality_label"
      >
      <div>
        <h6>
          {{ content[$i18n.locale].title }}
        </h6>
        <div class="dataset-quality-card">
          <label>
            {{ content[$i18n.locale].criterias.label }}
          </label>
          <ul>
            <li>
              {{ content[$i18n.locale].criterias.first }}
            </li>
            <li>
              {{ content[$i18n.locale].criterias.second }}
            </li>
            <li>
              {{ content[$i18n.locale].criterias.third }}
            </li>
            <li>
              {{ content[$i18n.locale].criterias.fourth }}
            </li>
          </ul>
          <a
            :href="`${publicPath}/specific/assets/quality_label_criterias.pdf`"
            target="_blank"
            download="CRITERES_LABEL_QUALITE.pdf"
          >
            {{ content[$i18n.locale].link }}
            <i class="bi bi-box-arrow-up-right" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'QualityLabel',

  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_PATH,
      content: {
        en: {
          title: 'Your dataset has been awarded the Minimum Quality Label. It meets the following criteria',
          criterias: {
            label: 'Quality of metadata',
            first: 'Essential fields completed',
            second: 'Reusable main resource',
            third: 'Fresh or vintaged data',
            fourth: 'Update frequency respected'
          },
          link: 'More about this indicator'
        },
        fr: {
          title: 'Votre jeu de données a obtenu le Label Minimal de qualité. Il remplit les critères suivants',
          criterias: {
            label: 'Qualité des métadonnées',
            first: 'Champs indispensables remplis',
            second: 'Ressource principale réutilisable',
            third: 'Donnée fraîche ou millésimée',
            fourth: 'Féquence de mise à jour respectée'
          },
          link: 'En savoir plus sur cet indicateur'
        }
      }
    };
  },

  computed: {
    ...mapGetters('datasets', [
      'currentDatasetExtras'
    ]),

    isComponentVisible() {
      if (this.currentDatasetExtras && this.currentDatasetExtras.length) {
        const qualityLabelExtra = this.currentDatasetExtras.find(extra => extra.key === 'quality_label');
        if (qualityLabelExtra && qualityLabelExtra.value === true) {
          return true;
        }
      }
      return false;
    }
  }
};
</script>

<style lang="less" scoped>
.dataset-quality {
  display: flex;
  align-items: center;
  justify-content: center;

  img.quality_label {
    height: 10rem;
    margin-right: 3rem;
  }

  h6 {
    font-weight: 600;
    color: @blue;
  }

  .dataset-quality-card {
    width: fit-content;
    padding: 0.5rem 0.75rem;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    margin: 1rem auto 0;
    color: #292929;

    label {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-right: 7rem;
      li:before {
        content: '✓';
        font-size: 1.2rem;
      }
    }

    a {
      font-size: 0.9rem;
      text-decoration: underline;
      text-underline-offset: 0.3rem;
      display: inline-block;
      width: 100%;
      text-align: right;
      color: #585858;
      i {
        margin-left: 0.1rem;
      }
    }
    a:hover {
      color: #292929;
    }
  }
}
</style>
